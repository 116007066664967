
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import '../sass/project.scss';
import ContactForm from './contact.jsx';

/* Project specific Javascript goes here. */
import axios from 'axios';
import React from 'react';
import ReactPlayer from 'react-player'
import { createRoot } from 'react-dom/client';
import { useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';



import { Navigation, Thumbs, Zoom } from 'swiper/modules';

const Gallery =(props)=> {
    // Parse the categories from JSON string to JavaScript object
    const categories = JSON.parse(props.categories);
    const [activeCatgory, setActiveCategory] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deviceType, setDeviceType] = useState(getDeviceType());
    const [selectedItem, setSelectedItem] = useState(null);
    const [divPosition, setDivPosition] = useState(0);
    const [showDiv, setShowDiv] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [details, setDetails] = useState(null);
    const [images, setImages] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);

    //let itemRefs = data ? data.reduce((acc, d) => ({ ...acc, [d.id]: React.createRef() }), {}) : {};

    const itemRefs = useRef(data ? data.reduce((acc, d) => ({ ...acc, [d.id]: React.createRef() }), {}) : {}); // Use useRef here
    const location = useLocation();

    const [swiper, setSwiper] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [activeAboutCategory, setActiveAboutCategory] = useState(null);


    //const navigate = useNavigate();

    const navigate = (id) => {
        const currentUrl = window.location.href.split('#')[0];
        const newUrl = `${currentUrl}#${id}`;
        window.history.pushState({}, '', newUrl);
        if(swiper) {
            swiper.destroy();
            setSwiper(null);
        }
        if(thumbsSwiper) {
            thumbsSwiper.destroy();
            setThumbsSwiper(null);
        }
    }

    const handleResize = (() => {
        const newDeviceType = getDeviceType();
        setDeviceType(newDeviceType);


        if (!data) {
            // Fetch data again if it's null
            if(props.initialItems) {
                setData(JSON.parse(props.initialItems));
            } else {
                setData([]);
            }
        }

        // Recalculate divPosition on resize
        const itemsPerRow = newDeviceType === 'mobile' ? 2 : newDeviceType === 'tablet' ? 3 : 6;
        if (data && selectedItem !== null) {
            const position = Math.ceil((selectedItem + 1) / itemsPerRow) * itemsPerRow - 1;
            if (position >= data.length) {
                setDivPosition(data.length - 1);
            } else {
                setDivPosition(position);
            }
        }
    });

    useEffect(() => {
        if(props.initialItems) {
            setData(JSON.parse(props.initialItems));
        } else {
            setData([]);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        setActiveCategory(parseInt(props.activeCategory));

        if(props.categories) {
            const cats = JSON.parse(props.categories);
            var active = cats.find(c => {
                return c.id === parseInt(props.activeCategory)
            });
            if (active) {
                setActiveAboutCategory(active.body);
            }
        }
    }, [props.activeCategory, props.categories]);

    useEffect( () => {

        async function fetchData(url) {
            setImageLoading(true);
            // const response = await fetch(url)
            //
            // if (response.ok) {
            //     const jsonData = await response.json();
            //     setThumbsSwiper(null);
            //     setSwiper(null);
            //     setImages(jsonData);
            //     setImageLoading(false);
            // } else {
            //     setImageLoading(false);
            // }

            await axios.get(url)
                .then(response => {
                    setImages(response.data);
                    setImageLoading(false);
                }).catch(error => {
                    setImageLoading(false);
                });

            // await fetch(url).then(async response => {
            //     setThumbsSwiper(null);
            //     setSwiper(null);
            //     const resp = await response.json()
            //     setImages(resp);
            //     setImageLoading(false);
            //
            // }).catch(error => {
            //     setImageLoading(false);
            //
            // })
        }
        // Get the id from the URL
        let id;

        if (location.hash) {
            id = location.hash.substring(1);
        }

        if (id && data) {
            const position = data.findIndex(d => d.slug === id);
            if (position >= 0 && position < data.length) {
                setSelectedItem(id);
                let itemsPerRow;
                if (deviceType === 'desktop') {
                    itemsPerRow = 6;
                } else if (deviceType === 'mobile') {
                    itemsPerRow = 2;
                } else if (deviceType === 'tablet') {
                    itemsPerRow = 3;
                }
                const rowNumber = Math.ceil((position + 1) / itemsPerRow);
                let lastIndexOfRow = rowNumber * itemsPerRow - 1;
                if (lastIndexOfRow >= data.length) {
                    lastIndexOfRow = data.length - 1;
                }
                setDivPosition(lastIndexOfRow);
                setDetails(data[position]);
                setShowDiv(true);

                fetchData(data[position].images);

                const scroller = document.getElementById(`scrolling-id-${data[position].id}`);
                if(scroller) {
                    scroller.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            }
        }
    }, [location.hash, data]);


    function getDeviceType() {
        const width = window.innerWidth;
        if (width <= 768) {
            return 'mobile';
        } else if (width <= 1024) {
            return 'tablet';
        } else {
            return 'desktop';
        }
    }

    const loadImages = (url) => {
        fetch(url)
            .then(response => response.json())
            .then(data => setImages(data));
    }

    const handleClick = (d) => async () => {
        console.log(d);
        const id = d.slug;
        setSelectedItem(id);

        if (selectedItem === id) {
            closeDiv();
            return;
        }

        setShowContact(false);
        setImages(null);
        setDetails(d);
        const position = data.findIndex(d => d.slug === id);

        if (position >= 0 && position < data.length) {
            let itemsPerRow;
            if (deviceType === 'desktop') {
                itemsPerRow = 6;
            } else if (deviceType === 'mobile') {
                itemsPerRow = 2;
            } else if (deviceType === 'tablet') {
                itemsPerRow = 3;
            }
            const rowNumber = Math.ceil((position + 1) / itemsPerRow);
            let lastIndexOfRow = rowNumber * itemsPerRow - 1;
            if (lastIndexOfRow >= data.length) {
                lastIndexOfRow = data.length - 1;
            }
            setDivPosition(lastIndexOfRow);
            setShowDiv(true);
        }

        navigate(id);
        setImageLoading(true);

        await axios.get(d.images)
            .then(response => {
                const resp = response.data;
                setImages(resp);
                setImageLoading(false);
                const scroller = document.getElementById(`scrolling-id-${d.id}`);
                if(scroller) {
                    scroller.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            })
            .catch(error => {
                setImageLoading(false);
            });
        //
        // await fetch(d.images).then(async response => {
        //     const resp = await response.json()
        //     setImages(resp);
        //     setImageLoading(false);
        // }).catch(error => {
        //     setImageLoading(false);
        // })
        // const response = await fetch(d.images)
        //
        // if(response.ok) {
        //     const jsonData = await response.json();
        //     setImages(jsonData);
        //     setImageLoading(false);
        // } else {
        //     setImageLoading(false);
        // }
    }


    const changeCategory = (id, url, api_url, body) => async () => {
        setActiveCategory(id);
        const newUrl = `${url}`;
        window.history.pushState({}, '', newUrl);

        // Set loading to true before sending the request
        setLoading(true);
        setSelectedItem(null);
        setDetails(null);
        setSwiper(null);
        setThumbsSwiper(null);
        setDivPosition(0);
        setImages(null);

        if(swiper) {
            swiper.destroy();
        }
        if(thumbsSwiper) {
            thumbsSwiper.destroy();
        }
        closeDiv();
        window.dispatchEvent(new Event('resize'));
        // Send the request

        await axios.get(api_url)
            .then(response => {
                const resp = response.data;
                itemRefs.current = resp ? resp.reduce((acc, d) => ({ ...acc, [d.id]: React.createRef() }), {}) : {};
                setData(resp);
                handleResize();
                setLoading(false);
                setActiveAboutCategory(body);
                handleResize();
            })
            .catch(error => {
                setLoading(false);
            });

        // await fetch(api_url).then(async response => {
        //     const resp = await response.json()
        //     itemRefs.current = resp ? resp.reduce((acc, d) => ({ ...acc, [d.id]: React.createRef() }), {}) : {};
        //     setData(resp);
        //     handleResize();
        //     setLoading(false);
        //     handleResize();
        // }).catch(error => {
        //     setLoading(false);
        // });

    }

    const closeDiv = () => {
        setShowDiv(false);
        setSelectedItem(null);
        setImages(null);
        if(swiper) {
            swiper.destroy();
        }
        if(thumbsSwiper) {
            thumbsSwiper.destroy();
        }
        const currentUrl = window.location.href.split('#')[0];
        window.history.replaceState({}, '', currentUrl);
    }

    const showContactForm = () => {
        setShowContact(!showContact);
    }

    const contactFormSubmitted = (result) => {
        console.log(result);
    }

    const itemsPerRow = deviceType === 'mobile' ? 2 : deviceType === 'tablet' ? 3 : 6;


    return (
        <>
            <div className="subcategories-buttons col-12 col-xl-10" role="group">
                {categories && categories.map((category, index) => (
                    <span className={`btn btn-outline-light px-3 ${activeCatgory === category.id ? 'active' : ''}`}
                          key={index}
                          onClick={changeCategory(category.id, category.url, category.api_url, category.body)}>
                        {category.title}
                    </span>
                ))}
            </div>
            <div className={`row g-3 g-md-4 gallery-items-wrapper ${loading ? 'loading' : ''}`}>
                {data && data.map((d, index) => {
                    return (
                        <React.Fragment key={`item-${d.id}-${activeCatgory}`}>
                            <div className={`col-6 col-md-4 col-lg-2`} id={`scrolling-id-${d.id}`}
                                 onClick={handleClick(d)} ref={itemRefs[d.id]}>
                                <div className={`gallery-item ${selectedItem === d.slug ? "active" : ''}`}>
                                    <div className="gallery-item-thumbnail">
                                        <img src={d.thumbnail} alt="" loading={'lazy'}/>
                                    </div>
                                    <div className="gallery-item-title">
                                        <h6 className="mb-0">
                                            {d.title}
                                        </h6>
                                    </div>
                                    <div className="stretched-link text-body-light text-decoration-none"></div>
                                </div>
                            </div>
                            {index === divPosition && details && (
                                <div
                                    className={`col-12 slider-wrapper position-relative ${showDiv ? 'd-block' : 'd-none'} `}>

                                    {props.pageType === 'projects' && (
                                        <>
                                        <span className="icon-link icon-link-swiper-close float-end mb-1"
                                              onClick={closeDiv}>
                                            <i className="bi bi-x-circle-fill ms-1"></i>
                                        </span>
                                            {imageLoading && (
                                                <div className="loading-placeholder d-block">
                                                    {props.lang === 'lt' ? 'Kraunasi...' : 'Loading...'}
                                                </div>
                                            )}
                                            {images && (
                                                <>
                                                    {!imageLoading && (
                                                        <>
                                                            <Swiper
                                                                pagination={{
                                                                    type: 'fraction',
                                                                    clickable: true,
                                                                }}
                                                                initialSlide={0}
                                                                navigation={true}
                                                                loop={true}
                                                                modules={[Navigation, Thumbs, Zoom]}
                                                                className="product-slider-xl"
                                                                thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                                                                //thumbs={{ swiper: thumbsSwiper}}
                                                                onSwiper={setSwiper}
                                                            >
                                                                {images.map((image, index) => {
                                                                    return image.t === 'image' ?
                                                                        <SwiperSlide key={index}>
                                                                            <div className="swiper-zoom-container">
                                                                                <img src={image.url}
                                                                                     alt={`${details.title} - ${index}`}
                                                                                     loading={"lazy"}/>
                                                                            </div>
                                                                        </SwiperSlide> :
                                                                        <SwiperSlide key={index}>
                                                                            <ReactPlayer url={image.url} playing={true}
                                                                                         light={image.big_thumbnail}
                                                                                         controls={true} volume={0.4}
                                                                                         width="100%" height="80%"
                                                                                         className='video-player object-fit-contain'/>
                                                                        </SwiperSlide>
                                                                })}

                                                            </Swiper>
                                                            {swiper && (
                                                                <Swiper
                                                                    onSwiper={setThumbsSwiper}
                                                                    spaceBetween={10}
                                                                    slidesPerView={7}
                                                                    slidesPerGroup={3}
                                                                    freeMode={false}
                                                                    threshold={10}
                                                                    watchSlidesProgress={true}
                                                                    modules={[Navigation, Thumbs]}
                                                                    centeredSlides={true}
                                                                    centeredSlidesBounds={true}
                                                                    className={`product-slider-thumbnail-secondary mt-3 ${images.length > 10 ? 'product-slider-thumbnail-secondary--xl' : ''}`}
                                                                >
                                                                    {images.map((image, index) => (
                                                                        <SwiperSlide key={index}>
                                                                            <img src={image.thumb}
                                                                                 alt={`${details.title} - ${index}`}
                                                                                 loading={"lazy"}/>
                                                                        </SwiperSlide>
                                                                    ))}
                                                                </Swiper>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {props.pageType === 'products' && (
                                        <>
                                            <div className="border-radius-outline bg-input-dark px-3 py-4 p-lg-4">
                                                <div className="row g-2 g-lg-5">
                                                    <div className="col-12 col-lg-6">
                                            <span
                                                className="item-descrition-card-header d-flex d-lg-none align-items-center justify-content-end"
                                                            onClick={closeDiv}>
                                                <i className="bi bi-x-circle-fill ms-1"></i>
                                            </span>

                                                        <div className="product-slider-thumbnail-wrapper mb-4 mb-lg-0">
                                                            {imageLoading && (
                                                                <div className="loading-placeholder small d-block">
                                                                    {props.lang === 'lt' ? 'Kraunasi...' : 'Loading...'}
                                                                </div>
                                                            )}
                                                            {images && (
                                                                <>
                                                                    {!imageLoading && (
                                                                        <>
                                                                            <Swiper
                                                                                pagination={{
                                                                                    type: 'fraction',
                                                                                }}
                                                                                initialSlide={0}
                                                                                navigation={true}
                                                                                loop={true}
                                                                                modules={[Navigation, Thumbs, Zoom]}
                                                                                className="product-slider-xl"
                                                                                thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                                                                                onSwiper={setSwiper}
                                                                            >
                                                                                {images.map((image, index) => {
                                                                                    return image.t === 'image' ?
                                                                                        <SwiperSlide key={index}>
                                                                                            <div
                                                                                                className="swiper-zoom-container">
                                                                                                <img src={image.url}
                                                                                                     alt={`${details.title} - ${index}`}
                                                                                                     loading={"lazy"}/>
                                                                                            </div>
                                                                                        </SwiperSlide> :
                                                                                        <SwiperSlide key={index}>
                                                                                            <ReactPlayer url={image.url}
                                                                                                         light={image.big_thumbnail}
                                                                                                         playing={true}
                                                                                                         controls={true}
                                                                                                         volume={0.4}
                                                                                                         width="80%"
                                                                                                         height="80%"
                                                                                                         className='video-player'/>
                                                                                        </SwiperSlide>
                                                                                })}
                                                                            </Swiper>
                                                                            {swiper && (
                                                                                <Swiper
                                                                                    onSwiper={setThumbsSwiper}
                                                                                    spaceBetween={10}
                                                                                    slidesPerView={7}
                                                                                    slidesPerGroup={3}
                                                                                    freeMode={true}
                                                                                    watchSlidesProgress={true}
                                                                                    modules={[Navigation, Thumbs]}
                                                                                    centeredSlides={true}
                                                                                    centeredSlidesBounds={true}
                                                                                    className={`product-slider-thumbnail-secondary mt-3 ${images.length > 7 ? 'product-slider-thumbnail-secondary--xl' : ''}`}
                                                                                >
                                                                                    {images.map((image, index) => (
                                                                                        <SwiperSlide key={index}>
                                                                                            <img src={image.thumb}
                                                                                                 alt={`${details.title} - ${index}`}
                                                                                                 loading={"lazy"}/>
                                                                                        </SwiperSlide>
                                                                                    ))}
                                                                                </Swiper>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}

                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        <div className="item-descrition-card d-flex flex-column h-100">
                                                        <div
                                                                className="item-descrition-card-header d-flex align-items-center justify-content-between">
                                                                <h2 className="fw-bold">{details.title}</h2>
                                                                <span
                                                                    className="icon-link d-none d-lg-block"
                                                                    onClick={closeDiv}>
                                                                    <i className="bi bi-x-circle-fill ms-1"></i>
                                                    </span>
                                                            </div>
                                                            {!showContact && (
                                                                <>
                                                                    <div
                                                                        className="item-descrition-card-body mt-auto text-body-grey">
                                                                        <div className="mb-4" dangerouslySetInnerHTML={{__html: details.body}} style={{fontSize: '1.25rem', fontWeight: '500'}}></div>
                                                                        <ul className="item-properties list-unstyled mb-5">
                                                                            {details.table && details.table.map((item, index) => (
                                                                                <li key={index}>{item[0]} : <span>{item[1]}</span>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                    <div className="item-descrition-card-footer">
                                                                        <div>
                                                            <span
                                                                className={`btn btn-primary px-3 ${showContact ? 'opacity-0' : ''}`}
                                                                onClick={showContactForm}>
                                                                {props.lang === 'lt' ? 'Siųsti užklausą' : 'Send request'}
                                                                <i className="bi bi-chevron-right ms-1"></i>
                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {showContact && (
                                                                <ContactForm csrf={props.csrf} onClose={showContactForm}
                                                                             onSubmit={contactFormSubmitted}/>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    )
                })}
            </div>
            {activeAboutCategory && (
                <div className="col-12 mt-5">
                    <div className="border-radius-outline bg-input-dark px-3 py-4 p-lg-4" dangerouslySetInnerHTML={{__html: activeAboutCategory}}></div>
                </div>
            )}
        </>
    );
}

const el = document.getElementById('gallery');

if (el != null) {
    const root = createRoot(el);
    root.render(
        <Router>
            <Gallery
                categories={el.getAttribute('data-categories')}
                activeCategory={el.getAttribute('data-active-category')}
                initialItems={el.getAttribute('data-initial')}
                pageType={el.getAttribute('data-page-type')}
                csrf={el.getAttribute('data-csrf')}
                lang={el.getAttribute('data-lang')}
            />
        </Router>
    );
}


const ServiceGallerySlider =(props)=> {

    const [swiper, setSwiper] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [images, setImages] = useState(JSON.parse(props.images));
    return (
        <>
            <Swiper
                pagination={{
                    type: 'fraction',
                    clickable: true,
                }}
                initialSlide={0}
                navigation={true}
                modules={[Navigation, Thumbs, Zoom]}
                className="service-slider-xl"
                thumbs={{ swiper: thumbsSwiper}}
                onSwiper={setSwiper}
            >
               {images.map((image, index)=> {
                    return image.t === 'image' ?
                        <SwiperSlide key={index}>
                            <div className="swiper-zoom-container">
                                <img src={image.url}
                                     alt={`${index}`}
                                     loading={"lazy"}/>
                            </div>
                        </SwiperSlide> :
                        <SwiperSlide key={index}>
                            <ReactPlayer url={image.url} light={image.big_thumbnail} playing={true} controls={true} volume={0.4} width="100%" height="100%" className={'servicePage'}/>
                        </SwiperSlide>
                })}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={3}
                slidesPerGroup={1}
                freeMode={false}
                threshold={10}
                watchSlidesProgress={true}
                loop={true}
                modules={[Navigation, Thumbs]}
                centeredSlides={true}
                centeredSlidesBounds={true}
                className={`service-slider-thumbnail-secondary mt-2 ${images.length > 10 ? 'service-slider-thumbnail-secondary--xl' : ''}`}
              >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={image.thumb} alt={`${index}`} loading={"lazy"} />
                    </SwiperSlide>
                ))}
            </Swiper>

        </>
    );
}

const imageGallery = document.getElementById('image-gallery');

if (imageGallery != null) {
    const root = createRoot(imageGallery);
    root.render(
        <Router>
            <ServiceGallerySlider
                images={imageGallery.getAttribute('data-images')}
                thumbnails={imageGallery.getAttribute('data-thumbnails')}
            />
        </Router>
    );
}

const ContactFormWrapper =(props)=> {

    const contactFormSubmitted = (result) => {
        console.log(result);
    }

    const onClose = () => {
        console.log('Closed');
    }

    return (
        <>
           <ContactForm csrf={props.csrf} onSubmit={contactFormSubmitted} onClose={onClose} />
        </>
    );
}


const contactForm = document.getElementById('contact-form');

if (contactForm != null) {
    const root = createRoot(contactForm);
    root.render(
        <Router>
            <ContactFormWrapper
                csrf={contactForm.getAttribute('data-csrf')}
            />
        </Router>
    );
}








/// sortable

import Sortable from 'sortablejs';



