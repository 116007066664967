import React, { useState } from 'react';

function ContactForm(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(`Name: ${name}, Email: ${email}, Message: ${message}, CSRF: ${props.csrf}`);
        // Here you would typically send the form data to your server
        props.onClose();
        props.onSubmit({'success': true});
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label htmlFor="name" className="form-label">Vardas :</label>
                <input id={'name'} className={'form-control'}  placeholder={'Vardas'} type="text" value={name} onChange={e => setName(e.target.value)} />
            </div>
            <div className="mb-4">
                <label htmlFor="email" className="form-label">El. paštas :</label>
                <input id={'email'} className={'form-control'}  placeholder={'El. paštas'} type="email" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="mb-4">
                <label htmlFor="message" className="form-label">Žinutė :</label>
                <textarea id={'message'} className={'form-control'} rows={5} placeholder={'Jūsų žinutė'} value={message} onChange={e => setMessage(e.target.value)} />
            </div>
            <div>
                <button className="w-100 btn btn-primary px-5" type="submit">Siųsti <i className="bi bi-chevron-right ms-1"></i></button>
            </div>
        </form>
    );
}

export default ContactForm;
